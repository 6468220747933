<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <v-btn text color="primary" @click="back">
          <v-icon left>fa-solid fa-left-long</v-icon>
          Voltar
        </v-btn>
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Consultar e filtrar faturas">
      <template v-slot:form>
        <v-form
          @submit.prevent="search"
          v-model="valid"
          ref="form"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseDatePicker
                label="Início de vigência"
                hide-details
                v-model="filter.startEffectiveDate"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseDatePicker
                label="Final de vigência"
                hide-details
                v-model="filter.endEffectiveDate"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <v-autocomplete
                label="Status"
                hide-details
                clearable
                outlined
                dense
                :items="['Fatura aberta', 'Fatura fechada']"
                v-model="filter.situation"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseInsuranceCompany
                label="Seguradora / Operadora"
                hide-details
                clearable
                v-model="filter.insuranceCompanyId"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseCustomersAutocomplete
                label="Cliente"
                hide-details
                clearable
                v-model="filter.customerId"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseBusinessUnit
                id="businessUnitId"
                name="businessUnitId"
                label="Unidades de negócio"
                hide-details
                clearable
                v-model="filter.businessUnitId"
              />
            </v-col>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="6"
              v-if="filter.businessUnitId"
            >
              <BaseUserByBusinessUnit
                id="userId"
                name="userId"
                label="Responsável"
                clearable
                hide-details
                :rules="[required]"
                :businessUnit="filter.businessUnitId"
                v-model="filter.userId"
              />
            </v-col>

            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseButton
                type="submit"
                height="40"
                color="primary"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
                :disabled="!valid"
              />
              <BaseButton
                height="40"
                color="primary"
                title="Limpar"
                outlined
                @click="clean"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de faturas" right>
      <template v-slot:right>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              text
              color="success"
              v-bind="attrs"
              v-on="on"
              @click="getXls"
            >
              <v-icon color="success">fa-regular fa-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar excel</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              text
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="getPdf"
            >
              <v-icon color="error">fa-regular fa-file-pdf</v-icon>
            </v-btn>
          </template>
          <span>Gerar pdf</span>
        </v-tooltip>
      </template>

      <template v-slot:table>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :sort-desc="true"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.customerName`]="{ item }">
            <span :title="item.customerName">
              {{ doTruncateText(item.customerName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.insuranceCompanyName`]="{ item }">
            <span :title="item.insuranceCompanyName">
              {{ doTruncateText(item.insuranceCompanyName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.expirationDate`]="{ item }">
            {{ formatDate(item.expirationDate) }}
          </template>

          <template v-slot:[`item.invoiceValue`]="{ item }">
            {{ formatMoney(item.invoiceValue) }}
          </template>
        </v-data-table>
      </template>
    </BaseTableList>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { downloadFile } from '@/helpers/download';
import { generatePdf } from '@/helpers/report';
import { doTruncateText } from '@/helpers/utils';
import { formatMoney, formatDate } from '@/helpers/formatting';
import { ReportService } from '@/services/api/report';

export default {
  mixins: [rulesMixin],

  data: () => ({
    valid: true,
    items: [],
    totalRecords: 0,
    options: {},
    filter: {
      startEffectiveDate: null,
      endEffectiveDate: null,
      insuranceCompanyId: null,
      customerId: null,
      situation: null,
      businessUnitId: null,
      userId: null
    },
    headers: [
      { text: 'Estipulante/Subestipulante', value: 'customerName', width: 250 },
      { text: 'Operadora', value: 'insuranceCompanyName', width: 250 },
      { text: 'Produto', value: 'productType', width: 200 },
      { text: 'Apólice ', value: 'contractCode', width: 110 },
      { text: 'Fatura', value: 'invoiceNumber', width: 110 },
      { text: 'Mês referência', value: 'referenceDate', width: 160 },
      { text: 'Vidas', value: 'amountLives', width: 100 },
      { text: 'Prêmio total', value: 'invoiceValue', width: 140 },
      { text: 'Dia de fatura', value: 'invoiceDay', width: 160 },
      { text: 'Fechamento da fatura', value: 'invoiceClosingDay', width: 230 },
      { text: 'Situação', value: 'situation', width: 140 },
      { text: 'Responsável', value: 'responsibleName', width: 200 }
    ]
  }),

  methods: {
    doTruncateText,
    formatDate,
    formatMoney,

    back() {
      this.$router.push({ name: 'relatorios' });
    },

    clean() {
      this.$refs.form.reset();

      this.items = [];
      this.totalRecords = 0;
    },

    async search() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const params = {
          page: page || 1,
          take: itemsPerPage || 10,
          orderProperty: sortBy ? sortBy[0] : null,
          orderDesc: sortDesc ? sortDesc[0] : null,
          ...this.filter
        };

        const reportService = new ReportService();
        const { status, data } = await reportService.getInvoices(params);

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getXls() {
      try {
        const reportService = new ReportService();
        const { status, data } = await reportService.getExportXlsInvoiceReport(
          this.filter
        );

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getPdf() {
      try {
        const reportService = new ReportService();
        const { status, data } = await reportService.getExportDataInvoiceRepot(
          this.filter
        );

        if (status === 200) {
          const columns = [
            { header: 'Estipulante/Subestipulante', dataKey: 'customerName' },
            { header: 'Operadora', dataKey: 'insuranceCompanyName' },
            { header: 'Produto', dataKey: 'productType' },
            { header: 'Apólice', dataKey: 'contractCode' },
            { header: 'Fatura', dataKey: 'invoiceNumber' },
            { header: 'Mês referência', dataKey: 'referenceDate' },
            { header: 'Vidas', dataKey: 'amountLives' },
            { header: 'Prêmio total', dataKey: 'invoiceValue' },
            { header: 'Dia de fatura', dataKey: 'invoiceDay' },
            { header: 'Fechamento da fatura', dataKey: 'invoiceClosingDay' },
            { header: 'Situação', dataKey: 'situation' },
            { header: 'Responsável', dataKey: 'responsibleName' }
          ];

          generatePdf(columns, data, 'Relatório de faturas');
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
